import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '@/components/HomePage.vue';
import AdminPanel from '@/views/AdminPanel.vue';
import AddPost from '@/views/AddPost.vue';
import EditPost from '@/views/EditPost.vue'; // ✅
import ArticlePage from '@/components/ArticlePage.vue';

const routes = [
  { path: '/', component: HomePage },
  { path: '/admin', component: AdminPanel },
  { path: '/add-post', component: AddPost },
  { path: '/edit-post', component: EditPost }, // ✅ New route
  { path: '/article/:slug', component: ArticlePage, name: 'ArticlePage' }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
