<template>
  <div class="home-container">
    <h1>Latest Articles</h1>
    <div v-if="posts.length > 0">
      <div v-for="post in posts" :key="post.slug" class="post-card">
        <h2>
          <router-link :to="'/article/' + post.slug">{{ post.title }}</router-link>
        </h2>
        <p v-html="post.shortDescription"></p>
        <p><strong>Tags:</strong> {{ post.tags ? post.tags.join(', ') : 'None' }}</p>
      </div>
    </div>
    <div v-else>
      <p>Loading articles...</p>
    </div>
  </div>
</template>

<script>
import { db } from '../firebaseConfig';
import { collection, getDocs } from 'firebase/firestore';

export default {
  data() {
    return {
      posts: [],
    };
  },
  async mounted() {
    try {
      const querySnapshot = await getDocs(collection(db, 'posts'));
      this.posts = querySnapshot.docs.map(doc => doc.data()); // Ensure slug is used
      console.log('✅ Loaded posts:', this.posts);
    } catch (error) {
      console.error('❌ Error fetching posts:', error);
    }
  }
};
</script>

<style>
.home-container {
  max-width: 800px;
  margin: auto;
  padding: 20px;
}

/* Home page container */
.home-container h1 {
  text-align: center;
  font-size: 2.5rem;
  color: white;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.4);
  margin-bottom: 30px;
  font-weight: 700;
}

/* Article card styling for HomePage */
.post-card {
  background: rgba(255, 255, 255, 0.92);
  color: #222;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.25);
  margin-bottom: 25px;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  border: none;
}

.post-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 25px rgba(0, 0, 0, 0.35);
}

.post-card h2 {
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: 12px;
  color: #222;
}

.post-card a {
  color: #e91e63;
  text-decoration: none;
  transition: color 0.2s;
}

.post-card a:hover {
  color: #c2185b;
  text-decoration: none;
}

.post-card p {
  font-size: 1.05rem;
  line-height: 1.6;
  margin-bottom: 12px;
  color: #333;
}

/* Tag styling */
.post-card strong {
  color: #e91e63;
}

/* Loading message styling */
.home-container > div > p {
  background: rgba(255, 255, 255, 0.8);
  padding: 15px;
  border-radius: 10px;
  text-align: center;
  font-size: 1.2rem;
  color: #555;
}
</style>