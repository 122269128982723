
<template>
  <div class="admin-panel">
    <h1>Edit Post</h1>

    <label>Select Post:</label>
    <select v-model="selectedPostId" @change="loadPost">
      <option v-for="post in posts" :key="post.id" :value="post.id">{{ post.title }}</option>
    </select>

    <div v-if="selectedPostId">
      <label>Title:</label>
      <input v-model="currentPost.title" @input="generateSlug" class="text-input" />

      <label>Slug:</label>
      <input v-model="currentPost.slug" class="text-input" disabled />

      <label>Short Description:</label>
      <div ref="shortEditor" class="quill-editor"></div>

      <label>Long Description:</label>
      <div ref="longEditor" class="quill-editor"></div>

      <button @click="updatePost">Save Changes</button>
      <button @click="deletePost" class="delete-button">Delete Post</button>

      <p v-if="statusMessage">{{ statusMessage }}</p>
    </div>
  </div>
</template>

<script>
import { collection, doc, getDocs, updateDoc, deleteDoc } from "firebase/firestore";
import { db } from "@/firebaseConfig";
import Quill from "quill";
import "quill/dist/quill.snow.css";

export default {
  data() {
    return {
      statusMessage: "",
      selectedPostId: "",
      posts: [],
      currentPost: {
        title: "",
        slug: "",
        shortDescription: "",
        longDescription: "",
        tags: [],
        image: ""
      },
      shortEditor: null,
      longEditor: null
    };
  },
  async mounted() {
    this.shortEditor = new Quill(this.$refs.shortEditor, this.editorOptions());
    this.longEditor = new Quill(this.$refs.longEditor, this.editorOptions());
    await this.fetchPosts();
  },
  methods: {
    editorOptions() {
      return {
        theme: "snow",
        modules: {
          toolbar: [
            [{ header: [1, 2, false] }],
            ["bold", "italic", "underline"],
            ["link", "image"],
            [{ list: "ordered" }, { list: "bullet" }],
            ["clean"]
          ]
        }
      };
    },
    generateSlug() {
      this.currentPost.slug = this.currentPost.title.toLowerCase().replace(/\s+/g, "-");
    },
    async fetchPosts() {
      const snap = await getDocs(collection(db, "posts"));
      this.posts = snap.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    },
    loadPost() {
      const post = this.posts.find(p => p.id === this.selectedPostId);
      if (post) {
        this.currentPost = { ...post };
        this.shortEditor.root.innerHTML = post.shortDescription || "";
        this.longEditor.root.innerHTML = post.longDescription || "";
      }
    },
    async updatePost() {
      try {
        await updateDoc(doc(db, "posts", this.selectedPostId), {
          title: this.currentPost.title,
          slug: this.currentPost.slug,
          shortDescription: this.shortEditor.root.innerHTML,
          longDescription: this.longEditor.root.innerHTML,
          tags: this.currentPost.tags,
          image: this.currentPost.image
        });
        this.statusMessage = "✅ Post updated!";
        await this.fetchPosts();
      } catch (e) {
        this.statusMessage = "❌ Error updating post: " + e.message;
      }
    },
    async deletePost() {
      try {
        await deleteDoc(doc(db, "posts", this.selectedPostId));
        this.statusMessage = "🗑️ Post deleted!";
        this.selectedPostId = "";
        await this.fetchPosts();
      } catch (e) {
        this.statusMessage = "❌ Error deleting post: " + e.message;
      }
    }
  }
};
</script>

<style>
.text-input {
  display: block;
  margin-bottom: 10px;
  padding: 8px;
  width: 100%;
  max-width: 600px;
}
.quill-editor {
  max-width: 600px;
  margin-bottom: 20px;
}
.delete-button {
  background-color: red;
  color: white;
  padding: 10px;
  margin-left: 10px;
  border: none;
  border-radius: 4px;
}
</style>
