<template>
  <div class="article-container" v-if="post">
    <Head>
      <title>{{ post.title }} | Top 5 Guru</title>
      <meta name="description" :content="generateMetaDescription(post.longDescription)" />
      <meta name="keywords" :content="post.tags ? post.tags.join(', ') : ''" />
      <!-- Primary Meta Tags -->
      <meta name="title" :content="`${post.title} | Top 5 Guru`" />
      <meta name="author" content="Top 5 Guru" />
      <!-- Open Graph / Facebook -->
      <meta property="og:type" content="article" />
      <meta property="og:title" :content="post.title" />
      <meta property="og:description" :content="generateMetaDescription(post.longDescription)" />
      <meta property="og:image" :content="post.image" />
      <meta property="og:url" :content="currentUrl" />
      <meta property="og:site_name" content="Top 5 Guru" />
      <!-- Twitter -->
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:title" :content="post.title" />
      <meta property="twitter:description" :content="generateMetaDescription(post.longDescription)" />
      <meta property="twitter:image" :content="post.image" />
      <!-- Canonical URL -->
      <link rel="canonical" :href="currentUrl" />
      <!-- Structured Data / Schema.org -->
      <script type="application/ld+json" v-if="structuredData" v-html="structuredData"></script>
      <!-- Additional SEO Meta Tags -->
      <meta name="robots" content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1" />
      <meta name="language" content="English" />
      <meta name="revisit-after" content="7 days" />
    </Head>

    <h1>{{ post.title }}</h1>
    
    <div class="article-meta">
      <p><strong>Published:</strong> {{ formatDate(post.publishedDate || new Date()) }}</p>
      <p v-if="post.author"><strong>Author:</strong> {{ post.author }}</p>
      <p><strong>Tags:</strong> {{ post.tags ? post.tags.join(', ') : 'None' }}</p>
    </div>
    
    <img :src="post.image" :alt="post.title" class="article-image" v-if="post.image" />
    
    <div class="article-content" v-html="post.longDescription"></div>
    
    <!-- Table of Contents -->
    <div class="table-of-contents" v-if="tocLinks.length > 0">
      <h2>Table of Contents</h2>
      <ul>
        <li v-for="link in tocLinks" :key="link.id">
          <a :href="`#${link.id}`">{{ link.text }}</a>
        </li>
      </ul>
    </div>
    
    <!-- Related Posts -->
    <div class="related-posts" v-if="relatedPosts.length > 0">
      <h2>Related Posts</h2>
      <div class="related-posts-grid">
        <div v-for="relatedPost in relatedPosts" :key="relatedPost.slug" class="related-post-card">
          <router-link :to="'/article/' + relatedPost.slug">{{ relatedPost.title }}</router-link>
        </div>
      </div>
    </div>
    
    <!-- Share Buttons -->
    <div class="share-buttons">
      <h3>Share This Article</h3>
      <div class="share-grid">
        <a :href="`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(currentUrl)}`" target="_blank" rel="noopener noreferrer" class="share-button facebook">Facebook</a>
        <a :href="`https://twitter.com/intent/tweet?url=${encodeURIComponent(currentUrl)}&text=${encodeURIComponent(post.title)}`" target="_blank" rel="noopener noreferrer" class="share-button twitter">Twitter</a>
        <a :href="`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(currentUrl)}`" target="_blank" rel="noopener noreferrer" class="share-button linkedin">LinkedIn</a>
      </div>
    </div>
  </div>
  <div v-else class="loading-container">
    <p>Loading post...</p>
  </div>
</template>

<script>
import { db } from '../firebaseConfig';
import { collection, query, where, getDocs, limit } from 'firebase/firestore';
import { useRoute } from 'vue-router';

export default {
  data() {
    return {
      post: null,
      currentUrl: window.location.href,
      tocLinks: [],
      relatedPosts: [],
      structuredData: null,
    };
  },
  async mounted() {
    const route = useRoute();
    const slug = route.params.slug;

    if (!slug) {
      console.error('❌ No post slug found in route');
      return;
    }

    await this.loadPost(slug);
    this.extractTocLinks();
    this.generateStructuredData();
    this.fetchRelatedPosts();
  },
  methods: {
    async loadPost(slug) {
      try {
        const q = query(collection(db, 'posts'), where('slug', '==', slug));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          this.post = querySnapshot.docs[0].data();
          console.log('✅ Post loaded from Firestore:', this.post);
          
          // Add publication date if not present
          if (!this.post.publishedDate) {
            this.post.publishedDate = new Date();
          }
        } else {
          console.error('❌ No post found with slug:', slug);
        }
      } catch (error) {
        console.error('❌ Firestore query error:', error);
      }
    },
    extractTocLinks() {
      if (!this.post || !this.post.longDescription) return;
      
      // Use a temporary div to parse the HTML content
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = this.post.longDescription;
      
      // Find all headings (h2, h3, h4)
      const headings = tempDiv.querySelectorAll('h2, h3, h4');
      
      // Create TOC links
      this.tocLinks = Array.from(headings).map((heading, index) => {
        const id = `section-${index}`;
        heading.id = id; // Set ID on the actual heading
        
        return {
          id,
          text: heading.textContent,
          level: parseInt(heading.tagName.substring(1))
        };
      });
      
      // Update the post content with IDs added to headings
      if (this.tocLinks.length > 0) {
        this.post.longDescription = tempDiv.innerHTML;
      }
    },
    async fetchRelatedPosts() {
      if (!this.post || !this.post.tags || this.post.tags.length === 0) return;
      
      try {
        // Fetch posts with matching tags
        const q = query(
          collection(db, 'posts'),
          where('tags', 'array-contains-any', this.post.tags),
          limit(3)
        );
        
        const querySnapshot = await getDocs(q);
        let related = querySnapshot.docs.map(doc => doc.data());
        
        // Filter out the current post
        this.relatedPosts = related.filter(post => post.slug !== this.post.slug);
      } catch (error) {
        console.error('❌ Error fetching related posts:', error);
      }
    },
    generateMetaDescription(content) {
      if (!content) return '';
      
      // Create temporary div to strip HTML
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = content;
      
      // Get text content and limit to 160 characters
      let description = tempDiv.textContent.trim();
      if (description.length > 157) {
        description = description.substring(0, 157) + '...';
      }
      
      return description;
    },
    formatDate(date) {
      if (!date) return '';
      
      const d = new Date(date);
      return d.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    },
    generateStructuredData() {
      if (!this.post) return;
      
      const data = {
        "@context": "https://schema.org",
        "@type": "Article",
        "headline": this.post.title,
        "description": this.generateMetaDescription(this.post.longDescription),
        "image": this.post.image,
        "datePublished": (this.post.publishedDate || new Date()).toISOString(),
        "author": {
          "@type": "Person",
          "name": this.post.author || "Top 5 Guru"
        },
        "publisher": {
          "@type": "Organization",
          "name": "Top 5 Guru",
          "logo": {
            "@type": "ImageObject",
            "url": window.location.origin + "/logo.png"
          }
        },
        "mainEntityOfPage": {
          "@type": "WebPage",
          "@id": this.currentUrl
        }
      };
      
      this.structuredData = JSON.stringify(data);
    }
  }
};
</script>

<style>
/* Article container styling for ArticlePage */
.article-container {
  background: rgba(255, 255, 255, 0.92);
  border-radius: 20px;
  padding: 30px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  margin: 30px auto;
  max-width: 800px;
}

.article-container h1 {
  font-size: 2.2rem;
  font-weight: 700;
  margin-bottom: 20px;
  color: #222;
  text-align: center;
}

.article-meta {
  background: rgba(233, 30, 99, 0.1);
  padding: 12px 15px;
  border-radius: 8px;
  margin-bottom: 20px;
  font-size: 0.95rem;
}

.article-meta p {
  margin: 5px 0;
}

.article-image {
  width: 100%;
  border-radius: 12px;
  margin: 15px 0 25px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.article-content {
  font-size: 1.1rem;
  line-height: 1.8;
  color: #333;
}

.article-content h2, 
.article-content h3, 
.article-content h4 {
  margin-top: 30px;
  margin-bottom: 15px;
  color: #222;
}

.article-content p {
  margin-bottom: 20px;
}

.article-content img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  margin: 20px 0;
}

.article-content ul, 
.article-content ol {
  margin-left: 20px;
  margin-bottom: 20px;
}

.article-content li {
  margin-bottom: 8px;
}

/* Table of Contents */
.table-of-contents {
  background: rgba(0, 0, 0, 0.05);
  padding: 20px;
  border-radius: 10px;
  margin: 30px 0;
}

.table-of-contents h2 {
  font-size: 1.4rem;
  margin-bottom: 15px;
  color: #e91e63;
}

.table-of-contents ul {
  list-style-type: none;
  padding-left: 10px;
}

.table-of-contents li {
  margin-bottom: 10px;
}

.table-of-contents a {
  color: #333;
  text-decoration: none;
  transition: color 0.2s;
}

.table-of-contents a:hover {
  color: #e91e63;
  text-decoration: underline;
}

/* Related Posts */
.related-posts {
  margin-top: 40px;
  padding-top: 30px;
  border-top: 2px solid rgba(0, 0, 0, 0.1);
}

.related-posts h2 {
  font-size: 1.6rem;
  margin-bottom: 20px;
  color: #222;
}

.related-posts-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 15px;
}

.related-post-card {
  background: rgba(233, 30, 99, 0.08);
  padding: 15px;
  border-radius: 8px;
  transition: transform 0.2s ease-in-out, background 0.2s ease-in-out;
}

.related-post-card:hover {
  transform: translateY(-3px);
  background: rgba(233, 30, 99, 0.15);
}

.related-post-card a {
  color: #333;
  font-weight: 600;
  text-decoration: none;
  display: block;
}

/* Share Buttons */
.share-buttons {
  margin-top: 40px;
  padding-top: 25px;
  border-top: 2px solid rgba(0, 0, 0, 0.1);
}

.share-buttons h3 {
  font-size: 1.3rem;
  margin-bottom: 15px;
  color: #444;
}

.share-grid {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.share-button {
  padding: 8px 15px;
  border-radius: 5px;
  color: white;
  font-weight: 600;
  text-decoration: none;
  transition: opacity 0.2s;
}

.share-button:hover {
  opacity: 0.9;
}

.facebook {
  background: #3b5998;
}

.twitter {
  background: #1da1f2;
}

.linkedin {
  background: #0077b5;
}

/* Tag styling */
.article-container strong {
  color: #e91e63;
}

/* Loading message styling */
.loading-container {
  background: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 15px;
  text-align: center;
  font-size: 1.2rem;
  color: #555;
  max-width: 600px;
  margin: 50px auto;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}


/* Responsive styles */
@media (max-width: 768px) {
  .article-container {
    padding: 20px;
    margin: 15px;
  }
  
  .article-container h1 {
    font-size: 1.8rem;
  }
  
  .article-content {
    font-size: 1rem;
    line-height: 1.6;
  }
  
  .related-posts-grid {
    grid-template-columns: 1fr;
  }
}

/* Accessibility improvements */
.article-content a:focus,
.table-of-contents a:focus,
.related-post-card a:focus,
.share-button:focus {
  outline: 2px solid #e91e63;
  outline-offset: 2px;
}

/* Print styles for better PDF generation */
@media print {
  .article-container {
    box-shadow: none;
    margin: 0;
    padding: 0;
  }
  
  .share-buttons,
  .related-posts {
    display: none;
  }
}
</style>