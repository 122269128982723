import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBKcn9e1ynNMsCoFFrgQHbTaIvlf3MHZ4A",
  authDomain: "topguru-39018.firebaseapp.com",
  projectId: "topguru-39018",
  storageBucket: "topguru-39018.appspot.com",
  messagingSenderId: "11229431409",
  appId: "1:11229431409:web:a2d478422e31efc4c23dd0",
  measurementId: "G-CCTBQSWN85"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db, storage };
