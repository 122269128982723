
<template>
  <div class="admin-panel">
    <h1>Add New Post</h1>

    <label>Title:</label>
    <input v-model="title" @input="generateSlug" class="text-input" />

    <label>Slug (Auto-generated):</label>
    <input v-model="slug" class="text-input" disabled />

    <label>Short Description:</label>
    <div :ref="setShortEditorEl" class="quill-editor"></div>

    <label>Long Description:</label>
    <div :ref="setLongEditorEl" class="quill-editor"></div>

    <button @click="submitPost">Add Post</button>
    <p v-if="statusMessage">{{ statusMessage }}</p>
  </div>
</template>

<script setup>
import { ref, onMounted, nextTick } from "vue";
import { collection, addDoc } from "firebase/firestore";
import { db } from "@/firebaseConfig";
import Quill from "quill";
import "quill/dist/quill.snow.css";

const title = ref("");
const slug = ref("");
const statusMessage = ref("");

let shortEditorEl = null;
let longEditorEl = null;
let shortQuill = null;
let longQuill = null;

const setShortEditorEl = (el) => {
  shortEditorEl = el;
};
const setLongEditorEl = (el) => {
  longEditorEl = el;
};

const generateSlug = () => {
  slug.value = title.value.toLowerCase().replace(/\s+/g, "-");
};

const initQuill = () => {
  if (shortEditorEl && longEditorEl) {
    shortQuill = new Quill(shortEditorEl, quillOptions());
    longQuill = new Quill(longEditorEl, quillOptions());
  }
};

const quillOptions = () => ({
  theme: "snow",
  modules: {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline"],
      ["link", "image"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["clean"],
    ],
  },
});

const submitPost = async () => {
  const shortDescription = shortQuill?.root.innerHTML || "";
  const longDescription = longQuill?.root.innerHTML || "";

  try {
    await addDoc(collection(db, "posts"), {
      title: title.value,
      slug: slug.value,
      shortDescription,
      longDescription,
      tags: [],
      image: "",
    });

    statusMessage.value = "✅ Post added!";
    window.open(`/article/${slug.value}`, "_blank");

    title.value = "";
    slug.value = "";
    shortQuill.setContents([]);
    longQuill.setContents([]);
  } catch (err) {
    statusMessage.value = "❌ " + err.message;
  }
};

onMounted(async () => {
  await nextTick();
  initQuill();
});
</script>

<style>
.text-input {
  display: block;
  margin-bottom: 10px;
  padding: 8px;
  width: 100%;
  max-width: 600px;
}
.quill-editor {
  max-width: 600px;
  margin-bottom: 20px;
}
</style>
